<template>
  <div>
    <div class="heading">
      Dashboard
    </div>
    <div>
      <div class="page-sub-heading">Subscriptions</div>
      <div class="subscriptions">
        <div
          v-if="subscriptions.length === 0 && !subscriptionsLoader"
          class="no-subscriptions"
        >
          <div class="subscription">No subscriptions yet.</div>
        </div>
        <div
          class="subscription"
          v-for="subscription in subscriptions"
          v-bind:key="subscription._id"
        >
          <span>{{ subscription.name }}</span>
          <span v-if="active(subscription)">Active</span>
          <span v-if="!active(subscription)">Inactive</span>
          <span v-if="subscription.ends_at">
            Valid until {{ subscription.ends_at | toDateString }}
          </span>
          <span v-if="active(subscription) && !subscription.cancelled_at">
            <a
              href="https://www.paypal.com/cgi-bin/webscr?cmd=_subscr-find&alias=S49HWUY536FJN"
              >Cancel
            </a>
          </span>
          <span v-if="subscription.cancelled_at">Cancelled</span>
        </div>
        <div v-if="subscriptionsLoader" class="subscription loader">
          Loading...
        </div>
        <div>
          <router-link to="/subscribe" class="button wide subscribe-now"
            >Subscribe Now</router-link
          >
        </div>
      </div>
      <div class="page-sub-heading">Getting started</div>
      <div class="steps">
        <div>
          1.
          <a
            href="https://chrome.google.com/webstore/detail/asin-grabber-light/gdmicanijbiglolpggafeahdicefofbg"
            target="_blank"
            aria-label="Download Chrome Extension"
            rel="noreferrer"
            >Install Asin Grabber Light Chrome extension</a
          >.
        </div>
        <div>2. Click the extension icon to open a popup.</div>
        <div>3. Sign in with your credentials inside the extension.</div>
        <div>4. Make sure you have an active subscription.</div>
        <div>5. Use the extension for as long as you need.</div>
      </div>
      <div class="page-sub-heading">Need help?</div>
      <div class="steps">
        <div>
          Contact us at
          <a href="mailto:light@arkgroup.org">light@arkgroup.org</a> in case you
          need any assistance.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import moment from '../common/moment-instance';

export default {
  data() {
    return {
      subscriptionsLoader: false
    };
  },
  computed: {
    ...mapGetters(['subscriptions'])
  },
  created() {
    this.getSubs();
  },
  watch: {
    // call again the method if the route changes
    $route: 'getSubs'
  },
  mounted() {},
  methods: {
    ...mapActions(['getSubscriptions']),
    getSubs: function() {
      this.subscriptionsLoader = true;
      this.getSubscriptions()
        .then(() => {
          this.subscriptionsLoader = false;
        })
        .catch(() => {
          this.subscriptionsLoader = false;
        });
    },
    active: function(subscription) {
      return (
        (subscription.ends_at &&
          moment(subscription.ends_at).isAfter(moment())) ||
        this.trial(subscription)
      );
    },
    trial: function(subscription) {
      return (
        subscription.trial_ends_at &&
        moment(subscription.trial_ends_at).isAfter(moment())
      );
    }
  },
  filters: {
    toDateString: function(date) {
      return moment(date)
        .local()
        .format('LL');
    }
  }
};
</script>

<style scoped lang="scss">
.page-sub-heading {
  font-size: 20px;
  font-weight: 400;
  line-height: 32px;
}

.subscriptions,
.steps {
  margin: 0 0 16px 0;
  line-height: 24px;
}

.subscription {
  background: #f3f3f3;
  padding: 8px;
  margin-bottom: 8px;

  span {
    + span::before {
      display: inline-block;
      padding-right: 4px;
      padding-left: 4px;
      content: '|';
    }
  }
}

.subscribe-now {
  margin: 8px 0;
}
</style>
